 .title-head{
    font-weight: bold;
    font-size: 28px;
}

.content .card-text{
    font-weight: bold;
    font-size: 20px;
}



.gentium-book-plus-regular {
    font-family: "Gentium Book Plus", serif;
    font-weight: 400;
    font-style: normal;
  }
  
  .gentium-book-plus-bold {
    font-family: "Gentium Book Plus", serif;
    font-weight: 700;
    font-style: normal;
  }
  
  .gentium-book-plus-regular-italic {
    font-family: "Gentium Book Plus", serif;
    font-weight: 400;
    font-style: italic;
  }
  
  .gentium-book-plus-bold-italic {
    font-family: "Gentium Book Plus", serif;
    font-weight: 700;
    font-style: italic;
  }